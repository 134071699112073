import React, { useState, useEffect } from 'react'
import {Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import ComponentLoader from '../ComponentLoader';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  googleUnitId: Yup.string(),
});

const CreateNewOrganizationForm = ({ entryData = null, closeModal, updateData }) => {
  const [googleOrgUnits, setGoogleOrgUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGoogleOrgUnits = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get('/google/organizations');
        setGoogleOrgUnits(response.units);
      } catch (e) {
        setGoogleOrgUnits([]);
      }

      setIsLoading(false);
    }

    fetchGoogleOrgUnits();
  }, []);

  const updateOrCreateOrganization = async (data) => {
    setIsLoading(true);
    const request = entryData
      ? apiClient.put(`/organization-unit/${entryData.id}`, data)
      : apiClient.post('/organization-unit', data)

    try {
      await request;
      updateData();
      closeModal();
      toastr.success(`${entryData ? 'Updated' : 'Created'} successfully`)
    } catch(e) {
      toastr.error(e?.error);
    }

    setIsLoading(false);
  }

  return (
    <div className="deploy-google-admin">

      {isLoading && <ComponentLoader />}

      <Formik
        initialValues={{
          name: entryData?.name || '',
          description: entryData?.description || undefined,
          googleUnitId: entryData?.googleUnitId || undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => updateOrCreateOrganization(values)}
        enableReinitialize
      >
        {({ errors, touched, handleChange, isValid, dirty }) => (
          <Form id="deploy-form" className="form">

            <FormGroup>
              <Field
                id="entry-name"
                className="form-control"
                type="text"
                name="name"
                placeholder="Name (ex. My Organization)"
                onChange={(e) => handleChange(e)}
              />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Field
                id="entry-description"
                className="form-control"
                type="textarea"
                as="textarea"
                name="description"
                placeholder="Description"
                onChange={(e) => handleChange(e)}
              />
              {errors.description && touched.description ? (
                <div className="error">{errors.description}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Field
                id="entry-googleUnitId"
                className="form-control"
                type="select"
                as="select"
                name="googleUnitId"
              >
                <option key="unit-disabled" value={null} disabled>-- Link Google Organization (Optional) --</option>
                <option key="unit-no-value" value={"--"}>-- Link No Google Organization --</option>
                { googleOrgUnits.map((unit) => <option key={`unit-${unit.id}`} value={unit.id}>{unit.path}</option>)}
              </Field>
            </FormGroup>

            <div className="form-actions">
              <Button
                type="submit"
                disabled={!isValid || !dirty}
                className="button button-default"
                color="primary"
              >
                {entryData ? 'Update' : 'Create'}
              </Button>
              <Button
                className="ml-2 button button-default-outlined"
                color="secondary"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CreateNewOrganizationForm.propTypes = {
  closeModal: PropTypes.func,
  updateData: PropTypes.func,
}

export default CreateNewOrganizationForm;
