import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import NetworkEvents from "../pages/NetworkEvents";
import DeviceEvents from "../pages/DeviceEvents";
import ApplicationsEvents from "../pages/ApplicationsEvents";
import IssueComponent from "../components/issues/IssueComponent";
import { networkIssuesSearchByColumnOptions } from "../components/issues/issuesHelper";
import LogsReported from "../components/LogsReported";

const issuesReportedFilters = [
  {
    value: 'guid',
    label: 'GUID',
  },
];

export const issuesTabs = [
  {
    id: 'reported',
    label: 'Reported',
    component: <IssueComponent
      issueLabel="Reported Issues"
      category="reported"
      tableColumns={networkIssuesSearchByColumnOptions('reported')}
    />,
  },
  {
    id: 'network',
    label: 'Network',
    component: <IssueComponent
      issueLabel="Network Issues"
      category="network"
      tableColumns={networkIssuesSearchByColumnOptions()}
    />,
  },
  {
    id: 'device',
    label: 'Device',
    component: <IssueComponent
      issueLabel="Device Issues"
      category="device"
      tableColumns={networkIssuesSearchByColumnOptions()}
    />,
  },
  {
    id: 'application',
    label: 'Application',
    component: <IssueComponent
      issueLabel="Application Issues"
      category="application"
      tableColumns={networkIssuesSearchByColumnOptions()}
    />,
  },
];

export const logsNetworkTableColumns = {
  USER_NAME: {
    header: 'Username/Type',
    accessor: 'userName'
  },
  TYPE: {
    header: 'Event Type',
    accessor: 'type'
  },
  START_TIME: {
    header: 'Event Start',
    accessor: 'startTime'
  },
  END_TIME: {
    header: 'Event End',
    accessor: 'endTime'
  },
  DURATION: {
    header: 'Event Duration',
    accessor: 'duration'
  },
  COUNTRY: {
    header: 'Country',
    accessor: 'country'
  },
  ISP: {
    header: 'ISP',
    accessor: 'isp'
  },
  GUID: {
    header: 'UID/GUID',
    accessor: 'guid'
  },
}

export const logsDeviceTableColumns = {
  CREATED_AT: {
    header: 'Created at',
    accessor: 'created_at'
  },
  NAME: {
    header: 'Name',
    accessor: 'username'
  },
  GUID: {
    header: 'UID/GUID',
    accessor: 'guid'
  },
  CPU: {
    header: 'CPU Utilization Value',
    accessor: 'avg_cpu_utilization'
  },
  MEMORY: {
    header: 'Memory Utilization Value',
    accessor: 'avg_memory_utilization'
  },
  BATTERY: {
    header: 'Battery Health',
    accessor: 'avg_battery_health'
  },
  OS: {
    header: 'Operating System',
    accessor: 'operating_system'
  },
}

export const logsApplicationTableColumns = {
  CREATED_AT: {
    header: 'Created at',
    accessor: 'created_at'
  },
  NAME: {
    header: 'User',
    accessor: 'username'
  },
  APPLICATION: {
    header: 'Application',
    accessor: 'label'
  },
  STATUS: {
    header: 'Application Status',
    accessor: 'status'
  },
  AVG_RESPONSE_TIME: {
    Header: 'Avg Network Response',
      accessor: 'response_time'
  },
  AVG_PAGE_LOAD: {
    Header: 'Avg Page Load',
    accessor: 'avg_page_load_time'
  },
  URL: {
    header: 'Application URL/IP',
    accessor: 'url'
  },
}

export const logsTabsArr = (onRowClick) => (
  [
    {
      id: '1',
      label: 'Reported',
      component: <LogsReported
        issueLabel="Reported Issues"
        tableColumns={issuesReportedFilters}
        onRowClick={onRowClick}
      />,
    },
    {
      id: '2',
      label: 'Network',
      component: <NetworkEvents onRowClick={onRowClick} />,
    },
    {
      id: '3',
      label: 'Device',
      component: <DeviceEvents onRowClick={onRowClick} />,
    },
    {
      id: '4',
      label: 'Application',
      component: <ApplicationsEvents onRowClick={onRowClick} />,
    },
  ]
);

export const renderTabs = (
  arr,
  activeTab,
  setActiveTab,
  isButtonStyle = false,
  noBorder = false,
  onRowClick
) => {
  const navLinkClassName = `${isButtonStyle ? ' button button-default' : ''}`;
  const tabContentClassName = `page-block${noBorder ? '' : ' page-block_border'}`
  
  return(
    <>
      <Nav tabs>
        {arr.map((arrItem, index) => {
          const currentIndex = (index + 1).toString();
          
          return(
            <NavItem key={currentIndex}>
              <NavLink
                className={`${activeTab === arrItem.id ? 'active' : null}${navLinkClassName}`}
                onClick={() => setActiveTab(arrItem.id)}
              >
                {arrItem.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab} className={tabContentClassName}>
        {arr.map((arrItem, index) => {
          const currentIndex = (index + 1).toString();
          return(
            <TabPane tabId={arrItem.id} key={arrItem.id}>
              {activeTab === arrItem.id && (
                arrItem.component
              )}
            </TabPane>
          );
        })}
      </TabContent>
    </>
  );
}
