import React, { useState, useEffect } from 'react';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import ComponentLoader from '../ComponentLoader';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import './ManageOrganizationAdminsForm.scss';

const ManageOrganizationAdminsForm = ({ entryData = null, closeModal, updateData }) => {
  const [selectedAdmins, setSelectedAdmins] = useState(entryData?.administrators || []);
  const [administrators, setAdministrators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAdministratorList = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get('/administrator-list');
        setAdministrators(response.administrators);
      } catch (e) {
        setAdministrators([]);
      }
      setIsLoading(false);
    };

    fetchAdministratorList();
  }, []);

  const getSortedAdmins = () => {
    return [...administrators].sort((a, b) => selectedAdmins.includes(b.id) - selectedAdmins.includes(a.id));
  }

  const getAdminName = (admin) => {
    if (admin.firstName && admin.lastName) {
      return `${admin.firstName} ${admin.lastName} (${admin.email})`;
    }

    return admin.email;
  }

  const handleCheckboxChange = (adminId) => {
    setSelectedAdmins((prev) =>
      prev.includes(adminId) ? prev.filter((id) => id !== adminId) : [...prev, adminId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      await apiClient.put(`/organization-unit-admins/${entryData.id}`, {
        userIds: selectedAdmins.map(Number),
      });
      updateData();
      closeModal();
      toastr.success('Administrator list successfully updated');
    } catch (e) {
      toastr.error(e?.error);
    }

    setIsLoading(false);
  };

  return (
    <div className="deploy-google-admin">
      {isLoading && <ComponentLoader />}

      <form id="deploy-form" className="form" onSubmit={handleSubmit}>
        <FormGroup>
          <div className="org-custom-select">
            {getSortedAdmins().map((admin) => (
              <div key={admin.id} className="option-wrapper" onClick={() => handleCheckboxChange(admin.id)}>
                <input
                  type="checkbox"
                  checked={selectedAdmins.includes(admin.id)}
                  className="option-checkbox"
                />
                <span className="option-text">{getAdminName(admin)}</span>
              </div>
            ))}
          </div>
        </FormGroup>

        <div className="form-actions">
          <Button type="submit" disabled={isLoading || selectedAdmins.length === 0} className="button button-default" color="primary">
            Update
          </Button>
          <Button className="ml-2 button button-default-outlined" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

ManageOrganizationAdminsForm.propTypes = {
  entryData: PropTypes.object,
  closeModal: PropTypes.func,
  updateData: PropTypes.func,
};

export default ManageOrganizationAdminsForm;
