import { toastr } from "react-redux-toastr"
import apiClient from '../../gears/apiClient';

export const fetchOrganizations = async (setOrganizationsData, setIsLoading, page) => {
  setIsLoading(true);
  try {
    const response = await apiClient.get('/organization-units', page);
    setOrganizationsData(response);
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}

export const deleteOrganization = async (
  id,
  organizationData,
  setOrganizationData,
  setIsLoading,
  onRejectButtonClick
) => {
  setIsLoading(true);
  try {
    await apiClient.delete(`/organization-unit/${id}`);
    setOrganizationData({ ...organizationData, units: organizationData.units.filter(unit => unit.id !== id)} );
    onRejectButtonClick();
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}