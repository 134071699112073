import React, {useEffect, useState} from 'react';
import ReactTable from '../../ReactTable';
import * as PropTypes from "prop-types";
import { ActionModalBody } from './GoogleDevicesBaseTableHelper';
import GoogleDevicesBaseTableColumns from "./GoogleDevicesBaseTableColumns";
import { fetchGoogleDevices, updateGoogleDeviceStatus } from '../../../pages/GoogleDevices/googleDevicesBaseHelper';
import { buildPaginationData } from '../../../utility/utils';
import ActionModal from '../../ActionModal';
import Pagination from '../../pagination';
import { Tooltip } from 'react-tooltip';

const GoogleDevicesBaseTable = ({
		needToUpdate,
		setNeedToUpdate,
		filter,
		setPage,
		page,
  }) => {
	const [devicesData, setDevicesData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [device, setDevice] = useState(null);
	const [action, setAction] = useState(null);

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [modalHasErrors, setModalHasError] = useState(false);

	const [isCustomReboot, setIsCustomReboot] = useState(false);
	const [rebootDelayMinutes, setRebootDelay] = useState(null);

	const paginatedFilter = (newPage) => ({ ...filter, page: newPage || page });

	const onModalOpen = (id, action) => {
		setAction(action);
		setDevice(id);

		setIsConfirmModalOpen(true);
	};

	const toSentenceCase = (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

	const paginationData = devicesData?.devices?.length > 0 ? buildPaginationData(devicesData) : null;

	const navigateToPage = (newPage) => {
		setPage(newPage);

    fetchGoogleDevices(setDevicesData, setIsLoading, paginatedFilter(newPage));
		setNeedToUpdate(false);
  }

	useEffect(() => {
		if(needToUpdate) {
			fetchGoogleDevices(setDevicesData, setIsLoading, paginatedFilter());
			setNeedToUpdate(false);
		}
	}, [needToUpdate])
	
	const onSort = (e) => console.log(e);

	const onRejectButtonClick = () => {
		setModalHasError(false);
		setIsCustomReboot(false);
		setRebootDelay(null);
		setIsConfirmModalOpen(false);
	}

	const getParams = () => {
		if (action === 'reboot') {
			if (isCustomReboot && rebootDelayMinutes) {
				return { delaySeconds: rebootDelayMinutes * 60 };
			}

			return { delaySeconds: 60 };
		}

		return null;
	}

	const onConfirmButtonClick = () => {
		updateGoogleDeviceStatus(device, action, getParams(), setIsLoading);
		setIsCustomReboot(false);
		setRebootDelay(null);
		setIsConfirmModalOpen(false);
	}
	
	return (
		<>
			<ReactTable
				columns={GoogleDevicesBaseTableColumns({ onModalOpen })}
				data={devicesData.devices}
				onSortChange={onSort}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={true}
			/>
			{(devicesData?.devices?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
			<Tooltip id="google-device-table-tooltip" />

			{isConfirmModalOpen && (
				<ActionModal
					isOpen={isConfirmModalOpen}
					confirm={onConfirmButtonClick}
					confirmLabel={toSentenceCase(action)}
					confirmDisabled={modalHasErrors}
					reject={onRejectButtonClick}
					rejectLabel="Cancel"
					headerText={`Are you sure you want to ${action}?`}
				>
					{ActionModalBody({ action, setModalHasError, isCustomReboot, setIsCustomReboot, rebootDelay: rebootDelayMinutes, setRebootDelay })}
				</ActionModal>
			)}
		</>
	);
};

GoogleDevicesBaseTable.propTypes = {
	needToUpdate: PropTypes.bool,
	setNeedToUpdate: PropTypes.func,
};

export default GoogleDevicesBaseTable;
