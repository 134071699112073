import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import UpdateExtensionLogo from '../../components/UpdateExtensionLogo';
import { getEXLogo, updateEXLogo } from '../../store/actions';
import HelpDeskForm from '../../components/forms/HelpDeskForm';
import ExtensionConfigurationForm from '../../components/forms/ExtensionConfigurationForm';
import AhoyTrack from '../../modules/AhoyTrack';
import BottomBanner from "../../components/forms/BottomBanner/BottomBanner";

const ExtensionConfiguration = ({
  currentEXLogo,
  dispatchGetEXLogo,
  dispatchUpdateEXLogo,
}) => {
  const [ imageSrc, setImageSrc ] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  AhoyTrack();
  AhoyTrack('input');

  useEffect(() => {
    dispatchGetEXLogo();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changeImage = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append('chrome_ex_logo', file);
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    setImageSrc(URL.createObjectURL(file));
    dispatchUpdateEXLogo(formData);
  };

  return (
    <div className="container-fluid content settings extension">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Extension Settings</h2>
          </div>
        </Col>
      </Row>
      <UpdateExtensionLogo currentEXLogo={currentEXLogo} changeImage={changeImage} imageSrc={imageSrc} />
      <div className="settings-customization recent-tests-card">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : null}
              onClick={() => { toggle('1'); }}
            >
              Extension Settings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : null}
              onClick={() => { toggle('2'); }}
            >
              Helpdesk Integration
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : null}
              onClick={() => { toggle('3'); }}
            >
              Bottom banner
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="page-block page-block_border">
          <TabPane tabId="1">
            <ExtensionConfigurationForm />
          </TabPane>
          <TabPane tabId="2">
            <HelpDeskForm currentEXLogo={currentEXLogo} imageSrc={imageSrc} />
          </TabPane>
          <TabPane tabId="3">
            <BottomBanner />
          </TabPane>
        </TabContent>
        {/* <div className="settings-customization-title">Results page</div>
        <ExtensionStatusForm currentEXLogo={currentEXLogo} imageSrc={imageSrc} /> */}
      </div>
    </div>
  );
};

ExtensionConfiguration.defaultProps = {
  currentEXLogo: null,
};

ExtensionConfiguration.propTypes = {
  dispatchGetEXLogo: PropTypes.func.isRequired,
  dispatchUpdateEXLogo: PropTypes.func.isRequired,
  currentEXLogo: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { currentEXLogo } = state.EXConfig;
  return { currentEXLogo };
};

const mapDispatchToProps = {
  dispatchGetEXLogo: getEXLogo,
  dispatchUpdateEXLogo: updateEXLogo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtensionConfiguration));
