import React, { useState, useEffect } from 'react';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import ComponentLoader from '../ComponentLoader';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import './ManageOrganizationAdminsForm.scss';

const ManageAdminOrganizationsForm = ({ entryData = null, closeModal, updateData }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState(entryData?.organizations || []);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOrganizationUnitList = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get('/organization-unit-list');
        setOrganizations(response.units);
      } catch (e) {
        setOrganizations([]);
      }
      setIsLoading(false);
    };

    fetchOrganizationUnitList();
  }, []);

  const getSortedOrganizations = () => {
    return [...organizations].sort((a, b) => selectedOrganizations.includes(b.id) - selectedOrganizations.includes(a.id));
  }

  const handleCheckboxChange = (orgId) => {
    setSelectedOrganizations((prev) =>
      prev.includes(orgId) ? prev.filter((id) => id !== orgId) : [...prev, orgId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      await apiClient.put(`/administrator-organizations/${entryData.id}`, {
        organizationIds: selectedOrganizations.map(Number),
      });
      updateData();
      closeModal();
      toastr.success('Organization list successfully updated');
    } catch (e) {
      toastr.error(e?.error);
    }

    setIsLoading(false);
  };

  return (
    <div className="deploy-google-admin">
      {isLoading && <ComponentLoader />}

      <form id="deploy-form" className="form" onSubmit={handleSubmit}>
        <FormGroup>
          <div className="org-custom-select">
            {getSortedOrganizations().map((org) => (
              <div key={org.id} className="option-wrapper" onClick={() => handleCheckboxChange(org.id)}>
                <input
                  type="checkbox"
                  checked={selectedOrganizations.includes(org.id)}
                  className="option-checkbox"
                />
                <span className="option-text">{org.name}</span>
              </div>
            ))}
          </div>
        </FormGroup>

        <div className="form-actions">
          <Button type="submit" disabled={isLoading} className="button button-default" color="primary">
            Update
          </Button>
          <Button className="ml-2 button button-default-outlined" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

ManageAdminOrganizationsForm.propTypes = {
  entryData: PropTypes.object,
  closeModal: PropTypes.func,
  updateData: PropTypes.func,
};

export default ManageAdminOrganizationsForm;
