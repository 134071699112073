import React, { useState } from 'react';
import {Row, Col, Button} from 'reactstrap';
import OrganizationsBaseTable from '../../components/tables/OrganizationsBaseTable/OrganizationsBaseTable';
import CreateNewOrganizationForm from '../../components/forms/CreateNewOrganizationForm';
import ModalComponent from '../../components/modal/ModalComponent';
import RefreshButton from '../../components/refresh-button';
import TableFilters from '../../components/TableFilters';

export const organizationTableFilters = [
  {
		label: 'Name',
    value: 'name',
  },
];

const Organizations = () => {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [needToUpdate, setNeedToUpdate] = useState(true);
	const [filter, setFilter] = useState(null);
	const [page, setPage] = useState(1);

	const onFilterChange = (filter) => {
    setFilter(filter);
		setPage(1);

		setNeedToUpdate(true);
  }
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="d-flex align-items-center mr-5">
						<span className="card-title mb-0">Organization List</span>
						<RefreshButton onRefresh={() => setNeedToUpdate(true)} />
					</div>
				</Col>
        <Col sm="6">
          <div className="page-actions d-flex flex-column align-items-end">
            <Button
              className="button button-default"
							onClick={() => setIsCreateModalOpen(true)}
            >
              Create
            </Button>
          </div>
        </Col>
			</Row>
			<div className="justify-between  mb-4 d-md-flex">
				<TableFilters
					currentFilter={filter}
					onFilterChange={onFilterChange}
					defaultSelect={organizationTableFilters?.[0]?.value}
					searchByColumnOptions={organizationTableFilters}
				/>
			</div>
			<div className="table-responsive">
				<OrganizationsBaseTable
					needToUpdate={needToUpdate}
					setNeedToUpdate={setNeedToUpdate}
					filter={filter}
					setPage={setPage}
					page={page}
				/>
			</div>

			{isCreateModalOpen && (
				<ModalComponent
					isModalOpen={isCreateModalOpen}
					modalHeaderTitle="Register a new organization"
					toggleModal={() => setIsCreateModalOpen(!isCreateModalOpen)}
					isModalFooter={false}
				>
					<CreateNewOrganizationForm
						closeModal={() => setIsCreateModalOpen(false)}
						updateData={() => setNeedToUpdate(true)}
					/>
				</ModalComponent>
			)}
		</div>
	);
};

export default Organizations;
