import React, { useEffect, useState } from 'react';
import {Row, Col } from 'reactstrap';
import { populateOrgUnits } from './googleDevicesBaseHelper'; 
import GoogleDevicesBaseTable from '../../components/tables/GoogleDevicesBaseTable/GoogleDevicesBaseTable';
import RefreshButton from '../../components/refresh-button';
import TableFilters from '../../components/TableFilters';

export const deviceTableFilters = [
  {
		label: 'Serial Number',
    value: 'deviceSerialNumber',
  },
	{
		label: 'Organization',
    value: 'organizationUnitPath',
		type: 'select',
		options: [],
  },
	{
		label: 'Email',
    value: 'email',
  },
];

const GoogleDevices = () => {
	const [needToUpdate, setNeedToUpdate] = useState(true);
	const [filter, setFilter] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		populateOrgUnits(deviceTableFilters[1]);
	}, []);

	const onFilterChange = (filter) => {
    setFilter(filter);
		setPage(1);

		setNeedToUpdate(true);
  }
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>
							Device List
						</h2>
					</div>
				</Col>
			</Row>

			<div className="page-block page-block_border">
				<Row className="align-items-center">
					<Col xl={12}>
						<div className="justify-between align-items-center mb-4 d-md-flex">
							<div className="align-items-center d-md-flex">
								<div className="d-flex align-items-center mr-5">
									<span className="card-title mb-0">Managed Devices</span>
									<RefreshButton onRefresh={() => setNeedToUpdate(true)} />
								</div>
							</div>
						</div>
						<div className="justify-between  mb-4 d-md-flex">
							<TableFilters
								currentFilter={filter}
								onFilterChange={onFilterChange}
								defaultSelect={deviceTableFilters?.[0]?.value}
								searchByColumnOptions={deviceTableFilters}
								withOptionFilter={true}
							/>
						</div>
						<div className="table-responsive">
							<GoogleDevicesBaseTable
								needToUpdate={needToUpdate}
								setNeedToUpdate={setNeedToUpdate}
								filter={filter}
								setPage={setPage}
								page={page}
							/>
						</div>
					</Col>
				</Row>
      </div>
		</div>
	);
};

export default GoogleDevices;
