import React from 'react';
import AdminForm from "../../../forms/AdminForm";
import Button from "../../../Button/Button";
import * as PropTypes from "prop-types";

function UpdateAdministrator({
 selectedUser,
 onSuccessSubmit,
 onCancelSubmit,
}) {	
	return (
		<>
			<AdminForm
				user={selectedUser}
				onSuccessSubmit={onSuccessSubmit}
			/>
			<div className="form-actions">
				<Button
					type="submit"
					form="user-form"
					className="button button-default"
					color="primary"
				>
					Update
				</Button>
				<Button
					className="ml-2 button button-default-outlined"
					color="secondary"
					onClick={onCancelSubmit}
				>
					Cancel
				</Button>
			</div>
		</>
	)
}

UpdateAdministrator.propTypes = {
	selectedUser: PropTypes.object,
	onSuccessSubmit: PropTypes.func,
	onCancelSubmit: PropTypes.func,
};

export default UpdateAdministrator;
