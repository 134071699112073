import React, { useCallback } from 'react';
import Button from "../../Button/Button";

const OrganizationsBaseTableColumns = ({ onModalOpen }) => {
	const formatTime = (value) => new Date(value).toLocaleString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});

	const truncateText = useCallback((text, maxLen) => {
		if (!text || text.length < maxLen) {
			return text;
		}
	
		return text.slice(0, maxLen) + '...';
	}, []);

	const columns = [
		{
			Header: '',
			accessor: 'googleUnitId',
			Cell: ({ value }) => (
				<span
					className={`mdi ${value ? 'mdi-google' : 'mdi-block-helper'}`}
					style={{ color: `${value ? '#4285F4' : 'black'}` }}
				></span>
			),
		},
		{
			Header: 'Name',
			accessor: 'name',
		},
		{
			Header: 'Google Organization',
			accessor: 'googleUnitName',
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ value }) => (<span> {truncateText(value, 70)} </span>),
		},
    {
			Header: 'Created At',
			accessor: 'createdAt',
			Cell: ({ value }) => (<span> {formatTime(value)} </span>),
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value}) => (
				<div className="actions" style={{display: 'inline-flex'}}>
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'admins')}
						title="Administrators"
					>
						<i className="mdi mdi-account-multiple" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'delete')}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default OrganizationsBaseTableColumns;
