import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactTable from '../../ReactTable';
import {toastr} from "react-redux-toastr";
import {fetchAdmins} from "../../../store/administrators/actions";
import UpdateAdministrator from "../../DashboardSettings/tabs/Administrators/UpdateAdministrator";
import ManageAdminOrganizationsForm from '../../forms/ManageAdminOrganizationsForm';
import * as PropTypes from "prop-types";
import {deleteAdmin} from "../../../store/administrators/actions";
import ModalComponent from '../../modal/ModalComponent';
import AdminsTableColumns from "./AdminsTableColumns";
import ActionModal from '../../ActionModal';

const AdminsTable = ({
    onSortChange,
    disableSortBy = false,
  }) => {
	const isLoading = useSelector(state => state.Admins.loading);
	const data = useSelector(state => state.Admins.data?.users);
	const dispatch = useDispatch();

	const [selectedUser, setSelectedUser] = useState();
	const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const onModalOpen = (value, content) => {
		setSelectedUser(data.find(({id}) => id === value));
		
		if(content === 'delete') {
			setIsDeleteModalOpen(true);
		}

		if(content === 'edit') {
			setIsEditModalOpen(true);
		}

		if(content === 'orgs') {
			setIsOrgModalOpen(true);
		}
	};
	
	const onSort = useCallback(({sortBy}) => {
		if (onSortChange) {
			if (sortBy.length > 0) {
				const [{id, desc}] = sortBy;
				onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
			} else {
				onSortChange();
			}
		}
	}, []);

	const onRejectButtonClick = () => {
			setIsDeleteModalOpen(false);
			setSelectedUser(null);
		}
	
	const onConfirmButtonClick = async () => {
		dispatch(deleteAdmin(selectedUser.id));

		setSelectedUser(null);
		setIsDeleteModalOpen(false);
		
		toastr.success('System', `Administrator ${selectedUser.email} removed successfully`);
	}
	
	const onSuccessSubmit = () => {
		dispatch(fetchAdmins());

		setSelectedUser(null);
		setIsEditModalOpen(false);
		
		toastr.success('System', 'Success administrator update');
	};

	const refreshData = () => {
		dispatch(fetchAdmins());
	}
	
	return (
		<>
			<ReactTable
				columns={AdminsTableColumns({ onModalOpen })}
				data={data}
				onSortChange={onSort}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={disableSortBy}
			/>

			{isOrgModalOpen && (
				<ModalComponent
					isModalOpen={isOrgModalOpen}
					modalHeaderTitle="Manage Organization Units"
					toggleModal={() => setIsOrgModalOpen(!isOrgModalOpen)}
					isModalFooter={false}
				>
					<ManageAdminOrganizationsForm
						entryData={selectedUser}
						closeModal={() => setIsOrgModalOpen(!isOrgModalOpen)}
						updateData={() => refreshData()}
					/>
				</ModalComponent>
			)}

			{isEditModalOpen && (
				<ModalComponent
					isModalOpen={isEditModalOpen}
					modalHeaderTitle="Edit Administrator"
					toggleModal={() => setIsEditModalOpen(!isEditModalOpen)}
					isModalFooter={false}
				>
					<UpdateAdministrator
						selectedUser={selectedUser}
						onSuccessSubmit={onSuccessSubmit}
						onCancelSubmit={() => setIsEditModalOpen(!isEditModalOpen)}
					/>
				</ModalComponent>
			)}

			{isDeleteModalOpen && (
				<ActionModal
					isOpen={isDeleteModalOpen}
					confirm={onConfirmButtonClick}
					confirmLabel="Delete"
					reject={onRejectButtonClick}
					rejectLabel="Cancel"
					headerText={`Confirm administrator deletion?`}
				>
					<div className='mt-2'>
						Warning: Deleting this administrator is irreversible.
						They will no longer be able to perform administrative tasks unless re-assigned.
					</div>
				</ActionModal>
			)}
		</>
	);
};

AdminsTable.propTypes = {
	onSortChange: PropTypes.func,
	disableSortBy: PropTypes.bool,
};

export default AdminsTable;
