import { toastr } from "react-redux-toastr"
import apiClient from '../../gears/apiClient';

export const populateOrgUnits = async (orgFilter) => {
  try {
    const response = await apiClient.get('/google/organizations');

    orgFilter.options = response.units.map((u) => ({
      label: u.path,
      value: u.path,
    }));
    
  } catch (e) {
    orgFilter.options = [];
  }
}

export const fetchGoogleDevices = async (setGoogleDevicesData, setIsLoading, filters) => {
  setIsLoading(true);
  try {
    const response = await apiClient.get(`/google/devices`, filters);
    setGoogleDevicesData(response);
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}

export const updateGoogleDeviceStatus = async (deviceId, action, params, setIsLoading) => {
  const responseSuccessMap = {
    disable: 'Device successfully disabled. Status will update in a few seconds.',
    enable: 'Device successfully enabled. Status will update in a few seconds.',
    reboot: 'Reboot in progress. Please wait while the system restarts.',
    wipe: 'User data successfully wiped from the device. The device is now ready for a new user.',
    powerwash: 'Powerwash initiated. All settings and data will be reset.',
  };

  setIsLoading(true);

  try {
    await apiClient.post(`/google/device-state/${deviceId}`, { action, ...params });

    toastr.success(responseSuccessMap[action]);
  } catch(e) {
    toastr.error(e?.error)
  }

  setIsLoading(false);
}