import apiClient from '../../../../gears/apiClient';

export const filterListOptions = [
	{
		value: 'email',
		label: 'Email',
	},
	{
		value: 'first_name',
		label: 'First name',
	},
	{
		value: 'last_name',
		label: 'Last name',
	},
	{
    value: 'organization',
    label: 'Organization',
    type: 'select',
    options: [],
  },
];

export const populateOrganizations = async (orgFilter) => {
  try {
    const response = await apiClient.get('/organization-unit-list');

    orgFilter.options = response.units.sort((a, b) => a.name.localeCompare(b.name)).map((u) => ({
      label: u.name,
      value: u.id,
    }));
    
  } catch (e) {
    orgFilter.options = [];
  }
};
