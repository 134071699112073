import { MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink } from 'react-router-dom';
import { ROLES } from "../../constants";

const SubmenuIcon = ({iconClassName}) => (
  <i className={iconClassName} />
);

const isOrgAdmin = (role) => role === ROLES.ORG_ADMIN;

const sidebarItems = (
  subMenus,
  toggleSubmenu,
  profileData,
  hasGoogleConfig,
  isBluetoothEnabled,
  isWebTestEnabled,
  openOnboardingModal
) => [
  {
    title: "Dashboard",
    icon: <SubmenuIcon iconClassName="mdi mdi-home-outline" />,
    open: subMenus.overview,
    onOpenChange: (open) => toggleSubmenu('overview', open),
    childrens: [
      {
        to: '/admin/overview',
        activeClassName: 'active',
        label: 'Overview',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/broadband-insights',
        activeClassName: 'active',
        label: 'Broadband Insights',
        isVisible: true,
        isDIsabled: false,
      },
    ]
  },
  {
    title: "Network",
    icon: <SubmenuIcon iconClassName="mdi mdi-lan" />,
    open: subMenus.network,
    onOpenChange: (open) => toggleSubmenu('network', open),
    childrens: [
      {
        to: '/admin/tests',
        activeClassName: 'active',
        label: 'Test Results',
        isVisible: true,
        isDIsabled: false,
      },
    ]
  },
  {
    title: "Users",
    icon: <SubmenuIcon iconClassName="mdi mdi-account-multiple-outline" />,
    open: subMenus.users,
    onOpenChange: (open) => toggleSubmenu('users', open),
    childrens: [
      {
        to: '/admin/users',
        activeClassName: 'active',
        label: 'Users List',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/invited-users',
        activeClassName: 'active',
        label: 'Invited Users',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/organization-management?tab=2',
        activeClassName: 'active',
        label: 'Administrators',
        isVisible: !isOrgAdmin(profileData?.role),
        isDIsabled: false,
      }
    ]
  },
  {
    label: "Issues",
    icon: <SubmenuIcon iconClassName="mdi mdi-alert-circle-outline" />,
    onMenuItemClick: (open) => toggleSubmenu('issues', open),
    active: subMenus.issues,
    activeClassName: 'active',
    to: '/admin/issues',
    isVisible: true,
    isDIsabled: false,
  },
  {
    label: "Logs",
    icon: <SubmenuIcon iconClassName="mdi mdi-clipboard-outline" />,
    onMenuItemClick: (open) => toggleSubmenu('logs', open),
    active: subMenus.logs,
    activeClassName: 'active',
    to: '/admin/logs',
    isVisible: true,
    isDIsabled: false,
  },
  {
    title: "Settings",
    icon: <SubmenuIcon iconClassName="mdi mdi-settings-outline" />,
    open: subMenus.settings,
    onOpenChange: (open) => toggleSubmenu('settings', open),
    isVisible: !isOrgAdmin(profileData?.role),
    childrens: [
      {
        to: '/admin/applications-settings',
        activeClassName: 'active',
        label: 'App Settings',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/announcements',
        activeClassName: 'active',
        label: 'Announcements',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/subscriptions',
        activeClassName: 'active',
        label: 'Subscriptions',
        isVisible: profileData?.main_contact_user,
        isDIsabled: false,
      },
      {
        to: '/admin/sdk-credentials',
        activeClassName: 'active',
        label: 'SDK Credentials',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/organization-management',
        activeClassName: 'active',
        label: 'Org Management',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/extension-settings',
        activeClassName: 'active',
        label: 'Extension Settings',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/issues-settings',
        activeClassName: 'active',
        label: 'Issues Settings',
        isVisible: true,
        isDIsabled: false,
      },
      {
        to: '/admin/browser-test-settings',
        activeClassName: 'active',
        label: 'Browser Test',
        isVisible: isWebTestEnabled && (profileData?.role?.toLowerCase() === 'super admin' || profileData?.role?.toLowerCase() === 'hubble admin'),
        isDIsabled: false,
      },
    ]
  },
  {
    label: "AI Knowledge Base",
    icon: <SubmenuIcon iconClassName="mdi mdi-book-open-outline" />,
    onMenuItemClick: (open) => toggleSubmenu('aiKnowledgeBase', open),
    active: subMenus.aiKnowledgeBase,
    activeClassName: 'active',
    to: '/admin/ai-knowledge-base',
    isVisible: true,
    isDIsabled: false,
  },
  {
    title: "Google Admin",
    icon: <SubmenuIcon iconClassName="mdi mdi-google" />,
    open: subMenus.googleAdmin,
    onOpenChange: (open) => toggleSubmenu('googleAdmin', open),
    childrens: [
      {
        to: '/admin/google-credentials',
        activeClassName: 'active',
        label: 'Credentials',
        isVisible: !isOrgAdmin(profileData?.role),
        isDIsabled: false,
      },
      {
        to: '/admin/google-devices',
        activeClassName: 'active',
        label: 'Devices',
        isVisible: hasGoogleConfig,
        isDIsabled: false,
      },
    ]
  },
  {
    title: "Help",
    icon: <SubmenuIcon iconClassName="mdi mdi-help-circle-outline" />,
    open: subMenus.help,
    onOpenChange: (open) => toggleSubmenu('help', open),
    childrens: [
      {
        activeClassName: 'active',
        label: 'Setup Guide',
        isVisible: true,
        isDIsabled: false,
        isButton: true,
        onMenuItemClick: openOnboardingModal,
      },
      {
        activeClassName: 'active',
        label: 'Contact Support',
        isVisible: true,
        isDIsabled: false,
        isLink: true,
      },
    ]
  },
];

const onNavLinkClick = (e, label, condition) => {
  const isDisabled = label.toLowerCase() !== 'subscriptions' && !condition
  if(isDisabled) {
    e.preventDefault()
  }
}

const renderMenuItem = ({
    icon = null,
    onMenuItemClick = null,
    to = '',
    activeClassName,
    label,
    active = true,
    isVisible,
    isButton = false,
    isLink = false,
  },
  isActivePlan
) => (
  <>
    { isVisible &&
      <MenuItem
        active={active}
        icon={icon}
        onClick={onMenuItemClick}
        key={label}
      >
        {isButton && (
          <button
            className="btn btn-link"
            type="button"
            onClick={onMenuItemClick}
          >
            {label}
          </button>
        )}
        {isLink && (
          <a href="mailto:contact@hubbleiq.com">{label}</a>
        )}
        {(!isLink && !isButton) && (
          <NavLink
            to={to}
            activeClassName={activeClassName}
            onClick={(e) => onNavLinkClick(e, label, isActivePlan)}
          >
            {label}
          </NavLink>
        )}
      </MenuItem>
    }
  </>
);

export const renderSidebarItems = (
  subMenus,
  toggleSubmenu,
  profileData,
  hasGoogleConfig,
  isBluetoothEnabled,
  isWebTestEnabled,
  openOnboardingModal,
  isActivePlan
) => (
  sidebarItems(
    subMenus,
    toggleSubmenu,
    profileData,
    hasGoogleConfig,
    isBluetoothEnabled,
    isWebTestEnabled,
    openOnboardingModal,
  ).map((item, i) => {
    if (item.isVisible === false) {
      return null;
    }

    return item?.childrens
    ? <SubMenu
        title={item.title}
        icon={item.icon}
        open={item.open}
        onOpenChange={item.onOpenChange}
        key={i}
      >
        {item.childrens.map(itemChildren => renderMenuItem(itemChildren, isActivePlan))}
      </SubMenu>
    : renderMenuItem(item, isActivePlan)
  })
);
