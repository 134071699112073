import React, {useEffect, useState} from 'react';
import ReactTable from '../../ReactTable';
import * as PropTypes from "prop-types";
import { fetchOrganizations, deleteOrganization } from '../../../pages/Organizations/organizationsBaseHelper';
import OrganizationsBaseTableColumns from './OrganizationsBaseTableColumns';
import ManageOrganizationAdminsForm from '../../forms/ManageOrganizationAdminsForm';
import CreateNewOrganizationForm from '../../forms/CreateNewOrganizationForm';
import { buildPaginationData } from '../../../utility/utils';
import ModalComponent from '../../modal/ModalComponent';
import ActionModal from '../../ActionModal';
import Pagination from '../../pagination';

const OrganizationsBaseTable = ({
		needToUpdate,
		setNeedToUpdate,
		filter,
		setPage,
		page,
  }) => {
	const [organizationData, setOrganizationData] = useState([]);
	const [selectedOrganization, setSelectedOrganization] = useState(null);
	const [selectedOrganizationData, setSelectedOrganizationData] = useState({});

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const paginationData = organizationData?.units?.length > 0 ? buildPaginationData(organizationData) : null;
	const paginatedFilter = (newPage) => ({ ...filter, page: newPage || page });

	useEffect(() => {
		if(needToUpdate) {
			fetchOrganizations(setOrganizationData, setIsLoading, paginatedFilter());
			setNeedToUpdate(false);
		}
	}, [needToUpdate])

	const navigateToPage = (newPage) => {
		setPage(newPage);

		fetchOrganizations(setOrganizationData, setIsLoading, paginatedFilter(newPage));
		setNeedToUpdate(false);
	}
	
	const onModalOpen = (value, content) => {
		setSelectedOrganization(value);
		
		if(content === 'delete') {
			setIsDeleteModalOpen(true);
		}

		if(content === 'edit') {
			setSelectedOrganizationData(organizationData?.units?.find(unit => unit.id === value));
			setIsEditModalOpen(true);
		}

		if(content === 'admins') {
			setSelectedOrganizationData(organizationData?.units?.find(unit => unit.id === value));
			setIsAdminModalOpen(true);
		}
	};
	
	const onSort = (e) => console.log(e);

	const onRejectButtonClick = () => {
		setIsDeleteModalOpen(false);
		setSelectedOrganization(null);
	}

	const onConfirmButtonClick = () => {
		deleteOrganization(
			selectedOrganization,
			organizationData,
			setOrganizationData,
			setIsLoading,
			onRejectButtonClick
		)
	}
	
	return (
		<>
			<ReactTable
				columns={OrganizationsBaseTableColumns({ onModalOpen })}
				data={organizationData.units}
				onSortChange={onSort}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={true}
			/>
			{(organizationData?.units?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}

			{isEditModalOpen && (
				<ModalComponent
					isModalOpen={isEditModalOpen}
					modalHeaderTitle="Edit Organization"
					toggleModal={() => setIsEditModalOpen(!isEditModalOpen)}
					isModalFooter={false}
				>
					<CreateNewOrganizationForm
						entryData={selectedOrganizationData}
						closeModal={() => setIsEditModalOpen(!isEditModalOpen)}
						updateData={() => setNeedToUpdate(true)}
					/>
				</ModalComponent>
			)}

			{isAdminModalOpen && (
				<ModalComponent
					isModalOpen={isAdminModalOpen}
					modalHeaderTitle="Select Organization Administrators"
					toggleModal={() => setIsAdminModalOpen(!isAdminModalOpen)}
					isModalFooter={false}
				>
					<ManageOrganizationAdminsForm
						entryData={selectedOrganizationData}
						closeModal={() => setIsAdminModalOpen(!isAdminModalOpen)}
						updateData={() => setNeedToUpdate(true)}
					/>
				</ModalComponent>
			)}

			{isDeleteModalOpen && (
				<ActionModal
					isOpen={isDeleteModalOpen}
					confirm={onConfirmButtonClick}
					confirmLabel="Delete"
					reject={onRejectButtonClick}
					rejectLabel="Cancel"
					headerText={`Confirm organization deletion?`}
				>
					<div className='mt-2'>
						If you delete this organization, all related data may be lost permanently.
						This action cannot be undone. Please make sure you want to proceed,
						as recovery will not be possible.
					</div>
				</ActionModal>
			)}
		</>
	);
};

OrganizationsBaseTable.propTypes = {
	needToUpdate: PropTypes.bool,
	setNeedToUpdate: PropTypes.func,
	page: PropTypes.number,
};

export default OrganizationsBaseTable;
