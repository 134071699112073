import apiClient from '../../gears/apiClient';

export const usersTableFilters = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'first_name',
    label: 'First name',
  },
  {
    value: 'last_name',
    label: 'Last name',
  },
  {
    value: 'guid',
    label: 'Guid',
  },
  {
    value: 'organization',
    label: 'Organization',
    type: 'select',
    options: [],
  },
  {
    value: 'last_restart_time',
    label: 'Last Restart Time',
    type: 'select',
    options: [
      {
        value: 'more_than_threshold',
        label: 'More Than Threshold',
      },
      {
        value: 'less_than_threshold',
        label: 'Less Than Threshold',
      },
      {
        value: 'no_restart',
        label: 'No restart',
      },
    ],
  },
  {
    value: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
  },
];

export const populateOrganizations = async (orgFilter) => {
  try {
    const response = await apiClient.get('/organization-unit-list');

    orgFilter.options = response.units.sort((a, b) => a.name.localeCompare(b.name)).map((u) => ({
      label: u.name,
      value: u.id,
    }));
    
  } catch (e) {
    orgFilter.options = [];
  }
}