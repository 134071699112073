import { toastr } from 'react-redux-toastr';
import aiChatAvatar from '../../images/ai-chat-avatar.svg';
import userChatAvatar from '../../images/user-solid.svg';
import styles from './ai-chat.module.scss';
import apiClient from '../../gears/apiClient';

export const AI_USER = 'ai';
export const HUMAN_USER = 'user';

export const TRANSCRIPT_USER = 'User';
export const TRANSCRIPT_ASSISTANT = 'Assistant';

export const fetchAiChatMsgHistory = async (id, msg, setLoadingState, setData) => {
  try {
    const response = await apiClient.post(`/disruption-log-chat/${id}`, { message: msg });
    setData(response.messages);
  } catch(e) {
    toastr.error(e?.error || e?.message)
  }
  setLoadingState(false);
}

export const fetchAiChatMsgSend = async (id, msg, setData) => {
  try {
    const response = await apiClient.post(`/disruption-latest-chat/${id}`, { message: msg })
    setData(prevState => ([
      ...prevState,
      ...response?.messages
    ]))
  } catch(e) {
    toastr.error(e?.error || e?.message)
  }
}

export const renderMessages = (data) => {
  return(
    data.map((msg, index) => (
      <div
        key={index}
        className={`${styles.chatMessage} ${
          msg.user === HUMAN_USER ? styles.userMessage : styles.aiMessage
        } ${styles.visible}`}
      >
        {msg.user === AI_USER && (
          <img src={aiChatAvatar} className={styles.avatar} alt="AI Chat Avatar"/>
        )}
        <div className={styles.messageText}>{msg.text}</div>
      </div>
    ))
  )
}

export const renderTranscript = (data) => {
  return(
    data.map((msg, index) => (
      <div
        key={index}
        className={`${styles.chatMessage} ${
          msg.speaker === TRANSCRIPT_USER ? styles.transcriptMessage : styles.aiMessage
        } ${styles.visible}`}
      >
        <img
          src={msg.speaker === TRANSCRIPT_ASSISTANT ? aiChatAvatar : userChatAvatar}
          className={styles.avatar}
          alt="Chat Avatar"
        />
        <div className={styles.messageText}>{msg.message}</div>
      </div>
    ))
  )
}

export const userRole = (role) => role === 'assistant' ? AI_USER : HUMAN_USER;