import {Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import Button from "../../Button/Button";

const GoogleDevicesBaseTableColumns = ({ onModalOpen }) => {
	const formatTime = (value) => new Date(value).toLocaleString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});

	const getTooltipProps = useCallback((value) => ({
		"data-tooltip-id": "google-device-table-tooltip",
		"data-tooltip-content": value,
		"data-tooltip-place": "top",
	}), []);

	const generateDeviceButton = useCallback(({ tooltipText, onClickFn, icon }) => {
		return (
			<Button
				{ ...getTooltipProps(tooltipText)}
				generalType="default"
				className="ml-2"
				type="button"
				onClick={onClickFn}
			>
				<i className={`mdi ${icon}`} />
			</Button>
		);
	}, []);

	const columns = [
		{
			Header: '',
			accessor: 'userIsOnline',
			Cell: ({ value }) => (
				<span
					{ ...getTooltipProps(value ? 'Online' : 'Offline')}
					className={`mdi ${value ? 'mdi-check-circle-outline' : 'mdi-minus-circle-outline'}`}
					style={{ color: `${value ? 'green' : 'gray'}`}}
				></span>
			),
		},
		{
			Header: 'Serial Number',
			accessor: 'serialNumber',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({ value }) => (<span> {value?.toLowerCase()} </span>),
		},
		{
			Header: 'Last Sync',
			accessor: 'lastSync',
			Cell: ({ value }) => (<span> {formatTime(value)} </span>),
		},
		{
			Header: 'User Email',
			accessor: 'user',
			Cell: ({row: {original}}) => {
				if (original.userId) {
					return (
						<Link className="link-dark" to={`/admin/user-status/${original.userId}`}>
							{original.user}
						</Link>
					);
				}

				return (<span> {original.user} </span>);
			} 
		},
		{
			Header: 'Organization',
			accessor: 'organization',
		},
    {
			Header: 'Model',
			accessor: 'model',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value, row: { original }}) => {
				const isActive = original?.status === 'ACTIVE';
				const isDisabled = original?.status === 'DISABLED';
				const isOperational = isActive || isDisabled;

				return (
					<div className="actions" style={{display: 'inline-flex'}}>
						{isActive && generateDeviceButton({
							tooltipText: 'Disable Device',
							onClickFn: () => onModalOpen(value, 'disable'),
							icon: 'mdi-power-off'
						})}
						{isDisabled && generateDeviceButton({
							tooltipText: 'Enable Device',
							onClickFn: () => onModalOpen(value, 'enable'),
							icon: 'mdi-power'
						})}
						{isOperational && generateDeviceButton({
							tooltipText: 'Reboot Device',
							onClickFn: () => onModalOpen(value, 'reboot'),
							icon: 'mdi-refresh'
						})}
						{isOperational && generateDeviceButton({
							tooltipText: 'Wipe Users',
							onClickFn: () => onModalOpen(value, 'wipe'),
							icon: 'mdi-account-off'
						})}
						{isOperational && generateDeviceButton({
							tooltipText: 'Powerwash Device',
							onClickFn: () => onModalOpen(value, 'powerwash'),
							icon: 'mdi-eraser'
						})}
					</div>
				);
			},
		},
	];
	
	return columns;
};

export default GoogleDevicesBaseTableColumns;
