import React from 'react';
import Button from "../../Button/Button";

const AdminsTableColumns = ({ onModalOpen }) => {
	const isAdmin = (value) => value.role === 'admin';
	const isActive = (value) => value.active === true;

	const columns = [
		{
			Header: 'Guid',
			accessor: 'guid',
		},
		{
			Header: 'First name',
			accessor: 'first_name',
		},
		{
			Header: 'Last name',
			accessor: 'last_name',
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value, row: { original }}) => (
				<div className="actions">
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'orgs')}
						disabled={!isAdmin(original) || !isActive(original)}
						title="Organizations"
					>
						<i className="mdi mdi-account-multiple" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						disabled={!isActive(original)}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'delete')}
						disabled={!isActive(original)}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default AdminsTableColumns;
